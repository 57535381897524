module.exports = [{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser'),
      options: {"plugins":[],"siteUrl":"https://rafayepes.com"},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-gtag/gatsby-browser'),
      options: {"plugins":[],"trackingId":"UA-135473244-1","head":false,"anonymize":true},
    },{
      plugin: require('/opt/build/repo/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
